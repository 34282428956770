






























import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      accommodation: [],
    });

    const fetchHotels = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/hotel/select`)
        .then(({ data: { hotels } }) => {
          state.accommodation = hotels
            .map((hotel: any) => ({
              rooms: hotel.rooms.map((el: any) => ({
                id: el.id,
                name: el.name,
                hotelName: hotel.location.name,
              })),
            }))
            .map((item: any) => item.rooms)
            .flat();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.accommodation = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchHotels);

    const selectAllRooms = () => {
      props.value.values = state.accommodation.map((el: any) => el.id);
    };

    const deleteItem = (item: any) => {
      props.value.values = props.value.values.filter((el: any) => el !== item);
    };

    watch(
      () => props.value.operation,
      () => {
        if (props.value.operation === null) {
          props.value.operation = "in";
        }
      }
    );

    return { state, selectAllRooms, deleteItem };
  },
});
